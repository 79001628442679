import Cookies  from "universal-cookie";

const cookies = new Cookies();

export const setCookie = (name, value = '', days = 30) => {
    
    if(!name) {
        return;
    }

    cookies.set(name, value, {maxAge: days*24*60*60})

}

export const getCookie = (name) => {

    if(!name) {
        return;
    }

    return cookies.get(name)
}
