import React from 'react'

import FormCashnow from '../../../components/Forms/FormCashnow';
import './index.scss';

const Order = () => {
    return (
        <div className="cashnowatm--order">
            <div className="container">
                <div className="cashnow__header">ЗАЯВКА НА КРЕДИТ</div>
                <div className="cashnowatm--order__content">
                    <div className="cashnowatm--order__form">
                        <FormCashnow />
                    </div>
                    <div className="cashnowatm--order__timeline">
                        <div className="cashnowatm--order__timeline-item">
                            <div className="cashnowatm--order__timeline-item-number">
                                1
                            </div>
                            <div className="cashnowatm--order__timeline-item-content">
                                Заповніть форму
                            </div>
                        </div>
                        <div className="cashnowatm--order__timeline-item">
                            <div className="cashnowatm--order__timeline-item-number">
                                2
                            </div>
                            <div className="cashnowatm--order__timeline-item-content">
                                Наш менеджер зателефонує вам і прийме заявку
                            </div>
                        </div>
                        <div className="cashnowatm--order__timeline-item">
                            <div className="cashnowatm--order__timeline-item-number">
                                3
                            </div>
                            <div className="cashnowatm--order__timeline-item-content">
                                Підпишіть кредитний договір у відділенні банку
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Order;