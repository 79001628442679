export default {
    START_FETCH: "START_FETCH",
    FINISH_FETCH: "FINISH_FETCH",
    FETCH_SUCCESS: "FETCH_SUCCESS",
    FETCH_FAIL: "FETCH_FAIL",
    ORDER_CARD: "ORDER_CARD",
    CHANGE_NAME: "CHANGE_NAME",
    VALID_NAME: "VALID_NAME",
    CHECKED_NAME: "CHECKED_NAME",
    CHANGE_INN: "CHANGE_INN",
    VALID_INN: "VALID_INN",
    CHECKED_INN: "CHECKED_INN",
    CHANGE_PHONE: "CHANGE_PHONE",
    VALID_PHONE: "VALID_PHONE",
    CHECKED_PHONE: "CHECKED_PHONE",
    RESET_FORM: "RESET_FORM",
    FETCH_ERROR: "FETCH_ERROR",
    SENDED_ORDER: "SENDED_ORDER",
    SET_USER: "SET_USER",
    TOGGLE_RULE: "TOGGLE_RULE",
    CHANGE_COMPANY_NAME: "CHANGE_COMPANY_NAME",
    VALID_COMPANY_NAME: "VALID_COMPANY_NAME",
    CHECKED_COMPANY_NAME: "CHECKED_COMPANY_NAME",
  }
  