//Core
import React from "react"
import { Helmet } from "react-helmet"

//Components
import Seo from "../container/Layout/Seo"
import CashNowCPA from "../modules/watermelon"

//Styles
import "../styles/main.scss"
import "./index.css"

const CashNowpage = () => (
  <>
    <Seo
      title={`Кредит готівкою від ТАСКОМБАНК`}
      description={`Спеціальна пропозиція для підприємств і компаній`}
      link={`https://cash-plus.tascombank.ua/`}
    ></Seo>
    <Helmet>
        <meta name="robots" content="noindex, nofollow" />
    </Helmet>
    <CashNowCPA></CashNowCPA>
  </>
)

export default CashNowpage
