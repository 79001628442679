import React from "react"
import jump from "jump.js"

import { getUrlParams } from "../../../helpers/params"
import { setCookie, getCookie } from "../../../helpers/cookie"

import Commision from "../../../images/cashNow/icons/commision.png"
import Insurance from "../../../images/cashNow/icons/insurance.png"
import Home from "../../../images/cashNow/icons/home.png"
import Procent from "../../../images/cashNow/icons/procent.png"

import Sum from "../../../images/cashNow/icons/money.png";
import Term from "../../../images/cashNow/icons/60.png";

// import Cash from '../../../images/cashNow/cash.png';
// import Cash from '../../../images/cashNow/rozhok_dengi_min.png';
import Cash from "../../../images/cashNow/groshi.png"

import "./index.scss"

const jumpToCalc = () => {
  document.querySelector(".cashnowatm--calculator").scrollIntoView({
    behavior: "smooth",
  })
}

const jumpToOrder = () => {
  document.querySelector(".cashnowatm--order").scrollIntoView({
    behavior: "smooth",
  })
}

const Main = () => {
  const { aff_sub, aff_id, click_id, admitad_uid } = getUrlParams()
  const userCookieSub = getCookie("CLICK_ID")
  const userCookieId = getCookie("AID")
  const userAdmitadId = getCookie("admitad_uid")

  if (userCookieSub) {
    if (userCookieSub === click_id) {
      setCookie("CLICK_ID", click_id, 30)
    } else {
      if (click_id) {
        setCookie("CLICK_ID", click_id, 30)
      }
    }
  } else {
    if (click_id) {
      setCookie("CLICK_ID", click_id, 30)
    }
  }

  if (userCookieId) {
    if (userCookieId === aff_id) {
      setCookie("AID", aff_id, 30)
    } else {
      if (aff_id) {
        setCookie("AID", aff_id, 30)
      }
    }
  } else {
    if (aff_id) {
      setCookie("AID", aff_id, 30)
    }
  }

  if (userAdmitadId) {
    if (userAdmitadId === admitad_uid) {
      setCookie("admitad_uid", admitad_uid, 30)
    } else {
      if (admitad_uid) {
        setCookie("admitad_uid", admitad_uid, 30)
      }
    }
  } else {
    if (admitad_uid) {
      setCookie("admitad_uid", admitad_uid, 30)
    }
  }

  return (
    <div className="cashnowatm--main">
      <div className="cashnowatm--main__wrapper">
        <div className="cashnowatm--main__image">
          <img src={Cash} style={{ maxWidth: "480px" }} />
        </div>
        <div className="cashnowatm--main__content">
          <div className="cashnowatm--main__content-header">
            КРЕДИТ ГОТІВКОЮ
          </div>
          <div className="cashnowatm--main__content-subheader">
          Спеціальна пропозиція <br /> для працівників вашого підприємства
          </div>
          <div className="cashnowatm--main__content-items">
            {/*<div className="cashnowatm--main__content-item">
                            <img src={Commision} />
                            ЩОМІСЯЧНА КОМІСІЯ – 2,9% 
                        </div>*/}
            <div className="cashnowatm--main__content-item">
              <img src={Sum} />
              Сума –&nbsp;<b> до 500 000 грн</b>
            </div>
            <div className="cashnowatm--main__content-item">
              <img src={Term} />
              Строк –&nbsp;<b> до 60 місяців</b>
            </div>
            <div className="cashnowatm--main__content-item">
              <img src={Procent} />
              Річна ставка –&nbsp;<b> 0,01%</b>
            </div>
            <div className="cashnowatm--main__content-item">
              <img src={Commision} />
              Щомісячна комісія –&nbsp;<b> 2,2%</b>
            </div>
          </div>
          <div className="cashnowatm--main__content-controls">
            <button
              className="cashnowatm--main__btn"
              onClick={() => jumpToOrder()}
            >
              Подати заявку
            </button>
            {/*<button className="cashnowatm--main__btn" onClick={() => jumpToCalc()}>Розрахувати кредит</button>*/}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Main
