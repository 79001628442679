export const getUrlParams = () => {
  if (typeof window !== `undefined`) {
    return window.location.search
      .substr(1)
      .split("&")
      .map(el => el.split("="))
      .reduce((acc, arr) => ((acc[arr[0]] = arr[1]), acc), {})
  } else return {}
}

const TIME = 3600 * 24 * 90

export const setCookie = (name, value) =>
  (document.cookie = `${name}=${value}; max-age=${TIME}`)

export const getCookiesValue = cookieName => {
  var pattern1 = "(?:(?:^|.*;s*)"
  var pattern2 = "s*=s*([^;]*).*$)|^.*$"

  const regExp = new RegExp(pattern1 + cookieName + pattern2)

  return typeof window !== `undefined`
    ? document.cookie.replace(regExp, "$1")
    : ""
}
