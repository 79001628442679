import React from 'react'

import AppStore from '../../../images/cashNow/appstore.png';
import GooglePlay from '../../../images/cashNow/google-play.png';

import './index.scss';

const Footer = () => {
    return (
        <div className="cashnowatm--footer">
            <div className="container">
                {/* <div className="cashnow__header">Документи</div> */}
                <div className="cashnowatm--footer__content">
                    {/* <div className="cashnowatm--footer__docs"> */}
                        {/* <div className="cashnow--footer__doc">
                            <a target={'_blank'} href="https://tascombank.ua/docs">Тарифи та форми договорів</a>
                        </div>
                        <div className="cashnow--footer__doc">
                            <a target={'_blank'} href="https://tascombank.ua/docs">Управлінська звітність</a>
                        </div>
                        <div className="cashnow--footer__doc">
                            <a target={'_blank'} href="https://tascombank.ua/docs">Фінансова звітність та аудит</a>
                        </div>
                        <div className="cashnow--footer__doc">
                            <a target={'_blank'} href="https://tascombank.ua/docs">Статутні документи</a>
                        </div>
                        <div className="cashnow--footer__doc">
                            <a target={'_blank'} href="https://tascombank.ua/docs">Істотні характеристики</a>
                        </div>
                        <div className="cashnow--footer__doc">
                            <a target={'_blank'} href="https://tascombank.ua/docs/#60215c48ce924d00120e6d1f">Інформація для споживача</a>
                        </div> */}
                        {/*<div className="cashnowatm--footer__doc">
                            <a target={'_blank'} href="https://tascombank.ua/files/Istotni_kharakterystyky_poslugy_z_nadannia_spozhyvchogo_kredytu_Zruchna_gotivka_MAKSYMUM-21-02-2022.pdf">Істотні характеристики споживчого кредиту Зручна готівка МАКСИМУМ</a>
                        </div>*/}
                        {/*<div className="cashnowatm--footer__doc">
                            <a target={'_blank'} href="https://tascombank.ua/files/Poperedzhennia_ta_mozhlyvi_naslidky_dlia_korystuvacha_produktom_%C2%ABZruchna_gotivka_Maksymsdsdum%C2%BB-2604.pdf">Попередження та можливі наслідки для користувача продуктом «Зручна готівка Максимум»</a>
                        </div>*/}
                    {/* </div> */}
                    <div className="cashnowatm--footer__text">
                        {/*Мінімальна сума кредиту становить 1 000 грн, максимальна сума кредиту – 500 000 грн. Мінімальний строк кредиту – 6 місяців, максимальний – 60 місяців. Реальна річна процентна ставка залежить від розміру й строку кредиту та може складати від 42,48% до 267,28% річних. */}Детальна інформація на сайті tascombank.ua або за телефоном 0 800 503 580 (дзвінки по Україні безкоштовні). Тільки для повнолітніх.
                    </div>
                    <div className="cashnowatm--footer__text">
                        АТ «ТАСКОМБАНК».<br />Ліцензія НБУ №84 від 25.10.2011.<br/>Реєстрація НБУ №45 від 21.10.1991.<br />м. Київ, вул. Симона Петлюри, 30.
                    </div>
                    {/* <div className="cashnowatm--footer__apps">
                        <a target={'_blank'} href='https://apps.apple.com/app/tas2u/id1348286505?ls=1'>
                            <img src={AppStore} alt="App Store" />
                        </a>
                        <a target={'_blank'} href='https://play.google.com/store/apps/details?id=online.kapowai.tas2u'>
                            <img src={GooglePlay} alt="Google Play" />
                        </a>
                    </div> */}
                </div>
            </div>
        </div>
    )
}

export default Footer;