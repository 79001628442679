import React from 'react'
import InputField from './InputField';
import Checkbox from './Checkbox';
import ButtonSubmit from './ButtonSubmit';

import { onSucces, onFail, onSendSMS } from "../../UI/PopUp/index"

import useForm from "./hooks";

import './index.scss';

const Form = ({link, product}) => {

    const {
        state,
        smsContext,
        changeName,
        changePhone,
        checkedName,
        checkedInn,
        changeInn,
        checkedPhone,
        toggleRule,
        changeCompanyName,
        checkedCompanyName,
        onSend,
      } = useForm(link, product)
    const { name, inn, phone, companyName, fetch, success, fail, sendSMS, rule } = state
    console.log(companyName);

    return (
        <div className="order-form">
            <div className="order-form__wrapper">
                <div className="order-form__controls">
                    <form id="form" onSubmit={e => {
                        e.preventDefault()
                        onSend()
                    }}>
                    <InputField 
                        title={"Ваше ім'я"} 
                        type={'text'} 
                        placeholder={"Ім'я"} 
                        message={'Перевірте правильність вводу імені або прізвища'}
                        change={changeName}
                        checked={name.checked}
                        checkedFunc={checkedName}
                        value={name.value}
                        valid={name.valid}
                        full={true}
                    />
                    <InputField 
                        title={"Номер телефону"} 
                        type={'phone'} 
                        placeholder={'Номер телефону'} 
                        message={'Перевірте правильність вводу номеру телефона'}  
                        change={changePhone}
                        checked={phone.checked}
                        value={phone.value}
                        checkedFunc={checkedPhone}
                        valid={phone.valid}
                        full={true}
                        />
                    <InputField
                        title={"ІПН"}
                        type={'text'}
                        placeholder={"ІПН"}
                        message={'Перевірте правильність вводу ІПН'}
                        change={changeInn}
                        checked={inn.checked}
                        checkedFunc={checkedInn}
                        value={inn.value}
                        valid={inn.valid}
                        full={true}
                        />
                    <InputField
                        title={"Назва компанії"}
                        type={'text'}
                        placeholder={"Назва компанії"}
                        message={'Перевірте правильність вводу найменування компанії'}
                        change={changeCompanyName}
                        checked={companyName.checked}
                        checkedFunc={checkedCompanyName}
                        value={companyName.value}
                        valid={companyName.valid}
                        full={true}
                    />
                        
                        <Checkbox onClick={toggleRule} checked={rule} message={"Будь ласка, підтвердіть згоду на обробку та використання даних"}/>
                        <ButtonSubmit onClick={onSend} title={'Подати заявку!'} />
                        {sendSMS ? onSendSMS(phone.value, smsContext) : null}
                        {success ? onSucces() : null}
                        {fail ? onFail() : null}
                    </form>
                </div>
            </div>
        </div>
    )
}

export default Form