//Core
import React from "react"

//Components
import Header from './Header';
import Main from './Main';
import Calculator from "./Calculator";
import Order from "./Order";
import FAQ from "./FAQ";
import Footer from "./Footer";

//Styels
import "./index.scss";

const CashNow = () => {
  return (
    <div className="cashnowatm">
        <Header />
        <Main />
        {/*<Calculator />*/}
        <Order />
        <FAQ />
        <Footer />
    </div>
  )
}

export default CashNow
