import React, { useState, useEffect } from 'react'
import jump from 'jump.js';
import './index.scss'
import InputRange from './InputRange'

import {Calculate} from './helpCalculation';

const jumpToOrder = () => {
    jump('.cashnow--order', {
        duration: 1000,
        offset: 0,
        callback: undefined,
        a11y: false
      })
}

const delimetrFormat = (value) => {
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")
}

const Calculator = () => {

    const [sum, setSum] = useState(300000)
    const [term, setTerm] = useState(24)
    const [count, setCount] = useState(3)

    const {monthlyPayment, totalCredit, totalLoan, xirr} = Calculate(sum, term);

    const setTermToState = (arr) => {
        debugger
        if(arr) {
            setCount(arr[0]);
        }
        if(arr[0] === 1) {
            setTerm(6);
        }
        else if(arr[0] === 2) {
            setTerm(12);
        }
        else if(arr[0] === 3) {
            setTerm(24);
        }
        else if(arr[0] === 4) {
            setTerm(36);
        }
        else if(arr[0] === 5) {
            setTerm(48);
        }
        else if(arr[0] === 6) {
            setTerm(60);
        }
    }

    return (
        <div className="cashnow--calculator">
            <div className="container">
                <div className="cashnow__header">РОЗРАХУЙТЕ СВІЙ КРЕДИТ</div>
                <div className="cashnow--calculator__content">
                    <div className="cashnow--calculator__controls">
                        <div className="cashnow--calculator__control">
                            <div className="cashnow--calculator__control-label">
                                Я ХОЧУ ОТРИМАТИ
                            </div>
                            <div className="cashnow--calculator__control-value">
                                {delimetrFormat(sum)} грн
                            </div>
                            <div className="cashnow--calculator__control-range">
                                <InputRange step={1000} max={500000} min={1000} values={[sum]} change={setSum} />
                                <div className="cashnow--calculator__control-range-info">
                                    <span>1000 грн</span>
                                    <span>250 000 грн</span>
                                    <span>500 000 грн</span>
                                </div>
                            </div>
                        </div>
                        <div className="cashnow--calculator__control">
                            <div className="cashnow--calculator__control-label">
                                СТРОК КРЕДИТУ В МІСЯЦЯХ
                            </div>
                            <div className="cashnow--calculator__control-value">
                                {term} міс
                            </div>
                            <div className="cashnow--calculator__control-range cashnow--calculator__control-range--term">
                                <InputRange step={1} max={6} min={1} values={[count]} change={setTermToState} />
                                <div className="cashnow--calculator__control-range-info cashnow--calculator__control-range-info--term">
                                    <span>1 місяць</span>
                                    <span>60 місяців</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="cashnow--calculator__results">
                        <div className="cashnow--calculator__result">
                            <div className="cashnow--calculator__result-label">
                                щомісячний платіж
                            </div>
                            <div className="cashnow--calculator__result-value">
                                {monthlyPayment && delimetrFormat(monthlyPayment)} грн
                            </div>
                        </div>
                        <div className="cashnow--calculator__result">
                            <div className="cashnow--calculator__result-label">
                                загальна вартість кредиту
                            </div>
                            <div className="cashnow--calculator__result-value">
                                {totalLoan && delimetrFormat(totalLoan)} грн
                            </div>
                        </div>
                        <div className="cashnow--calculator__result">
                            <div className="cashnow--calculator__result-label">
                                загальні витрати за кредитом
                            </div>
                            <div className="cashnow--calculator__result-value">
                                {totalCredit && delimetrFormat(totalCredit)} грн
                            </div>
                        </div>
                        <div className="cashnow--calculator__result">
                            <div className="cashnow--calculator__result-label">
                                реальна річна % ставка
                            </div>
                            <div className="cashnow--calculator__result-value">
                                {xirr && xirr}%
                            </div>
                        </div>
                    </div>
                </div>
                <div className="cashnow--calculator__btn">
                    <button className="cashnow--main__btn" onClick={() => jumpToOrder()}>Замовити</button>
                </div>
            </div>
        </div>
    )
}

export default Calculator;